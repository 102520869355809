.ql-toolbar, .ql-container {
    font-size: inherit !important;
    font-family: inherit !important;
}

.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 14px 18.5px;
}

.ql-editor {
    padding: 0 !important;
}

.ql-container .ql-editor p,
.description-preview p {
    margin-bottom: 1em;
}

div.react-dropdown-tree-select .dropdown .dropdown-content {
    z-index: 10000;
}